.auth_module {
    min-height: 100vh;
    background: #2F333A;
    padding: 80px 15px;
    box-sizing: border-box;
}
img.auth-log {
    width: 273px;
    height: auto;
}
.logo-section {
    text-align: center;
    margin-bottom: 81px;
}
.ant-card.ant-card-bordered.Auth_card {
    width: 469px;
    max-width: 100%;
    margin: 0 auto;
    background: #191C1F;
    border: 1px solid #AAAAAA;
    border-radius: 16px;
}
.ant-card.ant-card-bordered.Auth_card .ant-card-body {
    padding: 67px 40px 68px;
}
.ant-card.ant-card-bordered.Auth_card .ant-card-body h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #F2F2F2;
    margin: 0 0 24px;
    text-align: center;
}
.ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper {
    height: 56px;
    border: 1px solid #AAAAAA;
    background: transparent;
    padding: 16px 20px 16px 24px;
    border-radius: 0;
}
.ant-card.ant-card-bordered.Auth_card .ant-input {
    background: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #Fff;
}
.ant-card.ant-card-bordered.Auth_card .ant-input::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #AAAAAA;
    opacity: 0.4;
}
.ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper span.anticon {
    color: rgb(170 170 170 / 40%);
    font-size: 20px;
}
.ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper.ant-input-affix-wrapper-status-success {
    border-color: #fff;
}
.ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper.ant-input-affix-wrapper-status-success span.anticon {
    color: #fff;
}
.ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error span.anticon {
    color: #fff;
}
.ant-card.ant-card-bordered.Auth_card .ant-form-item {
    margin-bottom: 16px;
}
.ant-card.ant-card-bordered.Auth_card .ant-form-item:last-child {
    margin-bottom: 0px;
}
.forgotpassword a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    /* text-decoration-line: underline; */
    color: #FFFFFF;
    border-bottom: 1px solid #fff;
}
.forgotpassword {
    margin-bottom: 24px;
    text-align: right;
}
.ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
    border: 1px solid #FF0000;
}
.ant-card.ant-card-bordered.Auth_card .ant-form-item button.ant-btn {
    background: linear-gradient(269.36deg, #FF4900 -23.62%, #FF9B06 120.63%);
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 16px 12px;
    text-align: center;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF !important;
    height: auto;
    transition: none;
}
.ant-card.ant-card-bordered.Auth_card .ant-form-item .ant-form-item-explain-error {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #FF0000;
}
.ant-card.ant-card-bordered.Auth_card .ant-form-item button.ant-btn:hover {
    background: linear-gradient(-269.36deg, #FF4900 -23.62%, #FF9B06 120.63%);
    color: #fff;
}
.ant-card.ant-card-bordered.Auth_card .ant-input:-internal-autofill-selected {
    background-color: transparent !important;
    color: #Fff !important;
    appearance: none !important;
    background-image: none !important;
    -webkit-appearance: none !important;
}
.forgot-icon {
    width: 60px;
    height: 60px;
    background: linear-gradient(269.36deg, #FF4900 -23.62%, #FF9B06 120.63%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin: 0 auto 18px;
    justify-content: center;
}
.forgot-icon img.key {
    width: 25px;
    height: auto;
}
.ant-card.ant-card-bordered.Auth_card.forgot-card h3 {
    margin-bottom: 18px;
}
.ant-card.ant-card-bordered.Auth_card.forgot-card p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #AAAAAA;
    margin: 0 0 18px;
    text-align: center;
}
.ant-card.ant-card-bordered.Auth_card.forgot-card .ant-form-item {
    margin-bottom: 18px;
}
.backtologin a:hover {
    text-decoration: underline;
}
.backtologin a {
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: right;
    color: #AAAAAA;
    justify-content: center;
}
.backtologin a img.arrow {
    width: 22px;
    margin-right: 7px;
}
.ant-card.ant-card-bordered.Auth_card.forgot-card .ant-card-body {
    padding: 44px 40px 45px;
}
.backtologin {
    text-align: center;
}
@media (max-width: 600px) {
    img.auth-log {
        width: 203px;
    }
    .logo-section {
        margin-bottom: 60px;
    }
    .ant-card.ant-card-bordered.Auth_card .ant-card-body, .ant-card.ant-card-bordered.Auth_card.forgot-card .ant-card-body {
        padding: 40px 25px;
    }
    .ant-card.ant-card-bordered.Auth_card .ant-card-body h3 {
        font-size: 27px;
        line-height: 38px;
    }
    .auth_module {
        padding: 60px 15px;
    }
    .ant-card.ant-card-bordered.Auth_card.forgot-card p {
        font-size: 14px;
        line-height: 25px;
    }
    .ant-card.ant-card-bordered.Auth_card .ant-input, .ant-card.ant-card-bordered.Auth_card .ant-input::placeholder {
        font-size: 14px;
        line-height: 22px;
    }
    .forgot-icon {
        width: 50px;
        height: 50px;
    }
    .forgot-icon img.key {
        width: 20px;
    }
    .ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper {
        height: 46px;
        padding: 11px 15px;
    }
    .ant-card.ant-card-bordered.Auth_card .ant-form-item button.ant-btn {
        padding: 11px 12px;
        font-size: 14px;
    }
}
@media (max-width: 480px) {
    img.auth-log {
        width: 170px;
    }
    .logo-section {
        margin-bottom: 40px;
    }
    .ant-card.ant-card-bordered.Auth_card .ant-card-body, .ant-card.ant-card-bordered.Auth_card.forgot-card .ant-card-body {
        padding: 25px 20px;
    }
    .ant-card.ant-card-bordered.Auth_card span.ant-input-affix-wrapper span.anticon {
        font-size: 17px;
    }
    .auth_module {
        padding: 40px 15px;
    }
    .ant-card.ant-card-bordered.Auth_card .ant-card-body h3 {
        font-size: 23px;
        line-height: 33px;
    }    
}