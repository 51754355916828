.ant-select.vendor-select .ant-select-selector {
    background: #191C1F;
    border: 1px solid #AAAAAA;
    border-radius: 2px;
    height: 56px;
    padding: 4px 20px;
}
.ant-select.vendor-select .ant-select-selector span.ant-select-selection-placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    color: #AAAAAA;
}
.ant-select.vendor-select span.ant-select-arrow {
    color: #aaa;
}
.ant-select.vendor-select .ant-select-selector span.ant-select-selection-item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    color: #fff;
}
.mapping-card-header h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.4px;
    color: #FF9B06;
    margin: 0 0 3px;
}
.list-table.mapping-data .ant-row {
    align-items: center;
}
.ant-card.ant-card-bordered.Map-card {
    background: #191C1F;
    border: 1px solid #AAAAAA;
    border-radius: 2px;
}
.mapping-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.map-card-action {
    display: flex;
    gap: 34px;
    align-items: center;
    padding-bottom: 4px;
    padding-right: 4px;
}
.map-card-action img {
    width: 20px;
    cursor: pointer;
}
.map-btn button.ant-btn.common-btn {
    width: 100%;
    padding: 12px;
    font-size: 14px;
}
.ant-card.ant-card-bordered.Map-card .ant-card-body {
    padding: 16px 14px;
}
.radio-btns .ant-radio-wrapper .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: #191c1e;
    border-color: #fff;
    border-width: 2px;
    overflow: auto;

}
.radio-btns label.ant-radio-wrapper span.ant-radio+* {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    padding-left: 11px;
}
.radio-btns .ant-radio-wrapper .ant-radio-inner::after {
    background: linear-gradient(269.36deg, #FF4900 -23.62%, #FF9B06 120.63%);
}
.radio-btns .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.555);
}
.radio-btns .ant-radio-wrapper span.ant-radio.ant-radio-checked span.ant-radio-inner {
    border-color: #f5770c;
}
.radio-btns .ant-radio-wrapper span.ant-radio.ant-radio-checked.ant-radio-disabled span.ant-radio-inner {
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgb(170 170 170 / 50%);
    cursor: not-allowed;
}
.radio-btns .ant-radio-wrapper span.ant-radio.ant-radio-disabled span.ant-radio-inner {
    background-color: rgb(255 255 255 / 10%);
    border-color: rgb(170 170 170 / 50%);
    cursor: not-allowed;
}
.radio-btns .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background: rgb(170 170 170 / 50%);
}
.radio-btns label.ant-radio-wrapper.ant-radio-wrapper-disabled span.ant-radio+* {
    color: rgb(170 170 170 / 50%);
}
.radio-btns label.ant-radio-wrapper {
    margin-right: 0;
    align-items: center;
}
.radio-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.radio-list-item a img {
    width: 14px;
    vertical-align: middle;
}
.radio-list-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.ant-card.ant-card-bordered.Map-card {
    margin-bottom: 36px;
    overflow: auto;
}
.test-save-btn {
    text-align: center;
    margin-bottom: 36px;
}
.test-save-btn button.common-btn {
    font-size: 14px;
}
.radio-list-item a {
    margin-left: 10px;
}
.map-btn {
    text-align: center;
}
.vendor-select.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: #aaa;
    box-shadow: none !important;
    outline: none !important;
}
.vendor-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #aaa;
    box-shadow: none !important;
    outline: none !important;
}
.vendor-select.ant-select-status-success .ant-select-selector {
    border-color: #fff;
}
.vendor-select.ant-select .ant-select-arrow span.anticon.anticon-down::after {
    position: absolute;
    width: 9.97px;
    height: 5px;
    top: -2px;
    right: 0;
    font-size: 12px;
    color: #fff;
    z-index: 10;
    content: "";
    background-image:  url(../../Assets/Images/down-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.vendor-select.ant-select .ant-select-arrow span.anticon.anticon-down {
    position: relative;
}
.vendor-select.ant-select .ant-select-arrow span.anticon.anticon-down svg {
    display: none;
}
.vendor-select.ant-select .ant-select-arrow {
    opacity: 0.4;
}
.radio-btns.map-result-btns .ant-space-align-center {
    align-items: flex-start;
  }
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper+label.ant-checkbox-wrapper {
    margin: 0;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper {
    align-items: center;
    gap: 11px;
    justify-content: flex-start;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox + span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    padding-left: 0px;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox span.ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: #191c1e;
    border-color: #fff;
    border-width: 2px;
    overflow: auto;
    border-radius: 50% !important;
    outline: none !important;
    box-shadow: none !important;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox.ant-checkbox-checked span.ant-checkbox-inner {
    border-color: #f5770c;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after{
    border: none;
    border-radius: 50%;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox {
    align-self: unset;
    transform: none;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox
.ant-checkbox-inner:after {
    top: 44%;
    inset-inline-start: 20.5%;
    display: table;
    width: 5.714286px;
    height: 11.142857px;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #f5770c;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper span.ant-checkbox.ant-checkbox-checked:after {
    border: none;
}
.ant-card.ant-card-bordered.Map-card .ant-checkbox-group label.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    opacity: 0.5;
}

.target-field-entry {
    display: flex;
    align-items: center;
}
@media(max-width: 1200px) {
    .map-card-action {
        gap: 20px;
    }
    .map-card-action img {
        width: 17px;
    }
}
@media(max-width: 991px) {
    .map-btn {
        margin-bottom: 28px;
    }
    .map-btn button.ant-btn.common-btn {
        width: auto;
        padding: 8px 22px;
    }
    .ant-select.vendor-select .ant-select-selector {
        height: 48px;
        padding: 0px 20px;
    }
}
@media(max-width: 767px) {
    .map-btn {
        margin-bottom: 28px;
    }
    .map-btn button.ant-btn.common-btn {
        width: auto;
        padding: 6px 22px;
    }
    .page-content-header.searchbox-header.map-page-header {
        padding-bottom: 20px;
    }
}
@media(max-width: 570px) {
    .pagecontent-header-rightbox .ant-select.vendor-select {
        width: 100% !important;
        margin-top: 20px;
    }
    .page-content-header.searchbox-header.map-page-header h3 {
        width: 100%;
    }    
    .pagecontent-header-rightbox .ant-select.vendor-select {
        width: 100% !important;
        margin-top: 20px;
    }
    .page-content-header.searchbox-header.map-page-header .pagecontent-header-rightbox {
        width: 100%;
    }
}