.ant-card.ant-card-bordered.color-card .ant-card-body {
    padding: 29px 20px 29px 26px;
    border-radius: 0;
}
.ant-card.ant-card-bordered.color-card {
    box-shadow: 4px 4px 4px rgb(42 139 213 / 2%);
    border-radius: 12px;
    border: none;
    margin-bottom: 16px;
}
.ant-card.ant-card-bordered.color-card.blue-card {
    background: #4255D9;
}
.ant-card.ant-card-bordered.color-card.pink-card {
    background: #F60390;
}
.ant-card.ant-card-bordered.color-card.green-card {
    background: #39C672;
}
.ant-card.ant-card-bordered.color-card.red-card {
    background: #F24A2F;
}
.color-icon-box {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(8, 8, 8, 0.22);
}
.color-icon-box img {
    height: 23px;
    width: auto;
}
.color-card-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.color-left-data h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    text-align: left;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    margin: 0;
    margin-top: -2px;
}
.color-left-data p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}
.ant-card.ant-card-bordered.dashboard-card {
    background: #191919;
    box-shadow: 4px 4px 4px rgb(42 139 213 / 2%);
    border-radius: 12px;
    border: none;
    margin-bottom: 16px;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-head {
    padding: 16px 24px 8px;
    border-bottom: 0.5px solid rgb(170 170 170 / 50%);
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-head-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 31px;
    text-align: left;
    letter-spacing: 0.4px;
    color: #AAAAAA;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-head .ant-card-extra img {
    height: 16px;
    opacity: 0.8;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-body {
    padding: 0 26px 8px;
}
.ant-card.ant-card-bordered.dashboard-card li.ant-list-item {
    padding: 16px 0;
    border-bottom: 0.5px solid rgb(170 170 170 / 50%);
}
.ant-card.ant-card-bordered.dashboard-card li.ant-list-item h4.ant-list-item-meta-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}
.ant-card.ant-card-bordered.dashboard-card li.ant-list-item ul.ant-list-item-action img {
    width: 36px;
    outline: none;
}
.ant-card.ant-card-bordered.dashboard-card li.ant-list-item:last-child {
    border-bottom: none;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item {
    padding: 8px 0;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta-avatar img {
    width: 40px;
    height: auto;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta-avatar {
    margin-right: 21px;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta-avatar {
    height: 40px;
}
.ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta {
    align-items: center;
}
.chart-div {
    height: 205px;
    margin-top: 14px;
    margin-bottom: 14px;
}
a.unmapped_all_link:hover {
    text-decoration: underline;
}
a.unmapped_all_link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 31px;
    text-align: left;
    letter-spacing: 0.4px;
    color: rgb(255 255 255 / 80%);
}
.ant-picker.ant-picker-range.custom-datepicker input, .ant-picker.ant-picker-range.custom-datepicker input::placeholder {
    color: #9a9a9a;
}
.ant-picker.ant-picker-range.custom-datepicker input {
    max-width: 90px;
}
.ant-picker.ant-picker-range.custom-datepicker .anticon {
    color: #9a9a9a;
}
.ant-picker.ant-picker-range.custom-datepicker .ant-picker-active-bar {
    background: #F24A2F;
}
.ant-picker.ant-picker-range.custom-datepicker span.ant-picker-clear {
    background: transparent !important;
}
.ant-picker.ant-picker-range.custom-datepicker span.ant-picker-clear .anticon {
    color: #fff;
}
@media (max-width: 1180px) {
    .ant-card.ant-card-bordered.color-card .ant-card-body {
        padding: 20px 15px 20px 15px;
        border-radius: 0;
    }
    .color-left-data h3 {
        font-size: 40px;
        line-height: 40px;
    }
    .color-icon-box img {
        height: 20px;
    }
    .color-icon-box {
        width: 45px;
        height: 45px;
    }
}
@media (max-width: 991px) {
    .ant-card.ant-card-bordered.dashboard-card .ant-card-head {
        padding: 16px 16px 10px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-head-title {
        font-size: 15px;
        line-height: 26px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-body {
        padding: 0 16px 8px;
    }
    .ant-card.ant-card-bordered.dashboard-card li.ant-list-item ul.ant-list-item-action img {
        width: 30px;
        outline: none;
    }
}
@media (max-width: 767px) {
    .ant-card.ant-card-bordered.dashboard-card .ant-card-head {
        padding: 16px 16px 12px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-head-title {
        font-size: 14px;
        line-height: 24px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-body {
        padding: 0 16px 8px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta-avatar {
        margin-right: 14px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta-avatar img {
        width: 35px;
        height: auto;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-body .ant-list.provider-list li.ant-list-item .ant-list-item-meta-avatar {
        height: 35px;
    }
    .ant-card.ant-card-bordered.dashboard-card li.ant-list-item h4.ant-list-item-meta-title {
        font-size: 13px;
        line-height: 22px;
    }
    .ant-card.ant-card-bordered.dashboard-card .ant-card-head .ant-card-extra img {
        height: 14px;
    }
}