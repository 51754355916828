/* --------------------------------------------------------------------------------------- */
/* Loader
/* --------------------------------------------------------------------------------------- */

.o-page-loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgb(0 0 0 / 12%);
  z-index: 99999;
  transition: opacity 0.5s, clip-path 1s;
  backdrop-filter: blur(12px);
}

.o-page-loader--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.o-page-loader--spinner {
width: 260px;
height: auto;
background: transparent;
margin: 20px auto;
animation: rotate-plane 1s infinite ease-in-out; 
-webkit-animation: rotate-plane 1s infinite ease-in-out;
max-width: 50%;
}
.o-page-loader--spinner img {
width: 100%;
/* animation: width-animate 1.2s infinite ease-in-out; 
-webkit-animation: width-animate 1.2s infinite ease-in-out; */
}

.o-page-loader--message {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

/* --------------------------------------------------------------------------- */
/* Animation - Loading Spinner
/* --------------------------------------------------------------------------- */


@-webkit-keyframes rotate-plane {
  0% {
    -webkit-transform: perspective(10px);
  }

  100% { 
    -webkit-transform: perspective(10px) scaleY(-1);
   }

  /* 100% {
    -webkit-transform: perspective(10px) scaleY(-1) scaleX(-1);
  } */
}
@keyframes rotate-plane {
  0% {
    transform: perspective(10px) scaleX(1) scaleY(1);
    -webkit-transform: perspective(10px) scaleX(1) scaleY(1);
  }

  100% {
    transform: perspective(10px) scaleX(-1) scaleY(1);
    /* -webkit-transform: perspective(10px) scaleX(-1) scaleY(1); */
  }

  /* 100% {
    transform: perspective(10px) scaleX(-1) scaleY(-1);
    -webkit-transform: perspective(10px) scaleX(-1) scaleY(-1);
  } */
}
